
import { defineComponent, ref } from 'vue';
import { useRouter } from 'vue-router';
import ConstRouter from '@/constants/constRouter';
import BaseButton from '@/components/Base/BaseButton.vue';
// import IconList from '@/components/Icon/IconList.vue';
import BaseProgresBar from '@/components/Base/BaseProgresBar.vue';
import BaseAvatar from '@/components/Base/BaseAvatar.vue';
import BaseModal from '@/components/Base/BaseModal.vue';
import SketetonLoader from '@/components/Base/BaseSkeletonLoader.vue';
import DefaultLayout from '@/layouts/DefaultLayout.vue';
import BackButton from '@/components/BackBtn.vue';
import { mapMutations } from 'vuex';
import constRouter from '@/constants/constRouter';
import { detailPools } from '@/apis/ApiPool';
// import { detailInvestmentPackage } from '@/apis/ApiContract';
import { numberToMoney } from '@/helpers/currency';
import * as HelperTime from '@/helpers/helperTime';
import * as Analytics from '@/services/analytics';
import { mapGetters } from 'vuex';
import IconCatogory from '@/components/Icon/IconCatogory.vue';
export default defineComponent({
  name: 'InvestmentPackageDetail',
  components: {
    BackButton,
    DefaultLayout,
    BaseAvatar,
    BaseProgresBar,
    // IconList,
    BaseButton,
    SketetonLoader,
    BaseModal,
    IconCatogory,
  },
  setup() {
    const router = useRouter();
    const handleClickNo = () => {
      router.push({
        name: ConstRouter.REGISTER_PHONE.name,
      });
    };
    const handleClickYes = () => {
      router.push({
        name: ConstRouter.REGISTER_PHONE.name,
      });
    };

    const investNow = ref<any>(null);
    return {
      handleClickNo,
      handleClickYes,
      investNow,
    };
  },
  data: () => ({
    hour: '00',
    minute: '00',
    second: '00',
    openModal: false,
    effectMenu: false,
    id: '' as any,
    thumbnail: null,
    name: null,
    description: null,
    content: null,
    otherContent: null,
    logoImage: [],
    timeLeft: null,
    closeDay: null,
    amount: 0,
    investedAmount: 0,
    investedLenders: [],
    intervalProp: 0,
    loading: false,
    isActive: true,
  }),
  computed: {
    ...mapGetters({
      user: 'getUser',
    }),
    percentInvest(): any {
      return Math.round((this.investedAmount / this.amount) * 100);
    },
  },
  created() {
    this.id = this.$route.params.id;

    this.fetchData();
  },
  mounted() {
    this.addEventScroll();
  },
  beforeUnmount() {
    this.removeEventScroll();
  },
  methods: {
    numberToMoney,
    ...mapMutations({
      setPackage: 'setPackage',
    }),
    closeModal() {
      this.openModal = false;
    },
    handleConfirm() {
      this.$router.push({
        name: constRouter.EKYC.name,
      });
    },
    fetchData() {
      this.loading = true;
      detailPools(this.id)
        .then((res: any) => {
          this.loading = false;
          const data = res.data;
          this.name = data.name;
          this.description = data.description;
          this.content = data.content;
          this.amount = data.amount;
          this.investedAmount = data.investedAmount;
          this.investedLenders = data.investedLenders;
          this.otherContent = data.otherContent;
          this.logoImage = data.logoImages;
          this.thumbnail = data.thumbnail;
          this.closeDay = data.closeDay;
          this.isActive = data.isActive;
          if (data.closeDay) {
            this.countdownTimer(data.closeDay);
          }
        })
        .catch((err) => {
          console.log('err', err);
        });
    },
    onInvest() {
      const id: any = this.$route.params.id;
      localStorage.setItem('packageId', id);
      if (this.user.nicNumber) {
        this.setPackage({
          name: this.name,
          id: this.$route.params.id,
          thumbnail: this.thumbnail,
        });
        this.$router.push({
          name: constRouter.INVESTMENT_INPUT_MOENY.name,
        });
        //analytics
        Analytics.eventK2A(
          'INVESTMENT',
          'DETAIL_POOL',
          'Nhấn đầu tư ở trang chi tiết pool',
          '',
        );
        // if (this.name === 'Gói ổn định') {
        //   this.$router.push({
        //     name: constRouter.INVESTMENT_INPUT_MOENY.name,
        //     query: { params: 'bank' },
        //   });
        // } else {
        //   this.$router.push({
        //     name: constRouter.INVESTMENT_INPUT_MOENY.name,
        //   });
        // }
      } else {
        this.openModal = true;
        //analytics
        Analytics.eventK2A('EKYC', 'ACCEPT_EKYC', 'Chọn EKYC', '');
      }
    },
    addEventScroll() {
      const el: any = document.getElementById('main-content');
      el.addEventListener('scroll', this.handleScroll);
    },
    removeEventScroll() {
      const el: any = document.getElementById('main-content');
      el.removeEventListener('scroll', this.handleScroll);
    },
    handleScroll() {
      if (this.investNow.getBoundingClientRect().top < 0) {
        this.effectMenu = true;
      } else {
        this.effectMenu = false;
      }
    },
    convertNumberTo2Digit(_number: number) {
      const number = _number + '';
      if (number.length < 2) {
        return '0' + number;
      }
      return _number;
    },
    countdownTimer(expireTime: string) {
      let duration: {
        hours: number;
        minutes: number;
        seconds: number;
        asSeconds: number;
      } | null;
      const interval = 1000; // 1 second

      // calculate difference between two times
      // based on time set in user's computer time / OS
      let currentTime = new Date().toString();
      // get duration between two times
      duration = HelperTime.diff2Date(expireTime, currentTime);

      // loop to countdown every 1 second
      this.intervalProp = setInterval(
        () => {
          currentTime = new Date().toString();
          // get updated duration
          duration = HelperTime.diff2Date(expireTime, currentTime);

          // if duration is >= 0
          if (duration && duration.asSeconds <= 0) {
            // this.countdown = false;
            clearInterval(this.intervalProp);
            // hide the countdown element
          } else {
            // this.countdown = true;
            // otherwise, show the updated countdown
            if (duration) {
              this.hour = this.convertNumberTo2Digit(duration.hours) + '';
              this.minute = this.convertNumberTo2Digit(duration.minutes) + '';
              this.second = this.convertNumberTo2Digit(duration.seconds) + '';
            }
          }
        },
        interval,
        true,
      );
    },
  },
});
